/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

@import "./assets/sass/style.angular.scss";

//html {
//  zoom: 80%;
//}



.ng-select-container {
  background: white !important;
  border: 1px solid #e4e6ef !important;
}
