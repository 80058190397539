//
// Main
//


// Body
body {
	background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

.br-1{
  border-radius: 0.425rem;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease,  margin-right get($aside-config, transition-speed) ease;

		// Aside default enabled and aside fixed modes
		.aside-enabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, width);
		}

		// Aside default enabled, aside fixed and aside minimize modes
		.aside-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, minimized-width);
		}

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, fixed, height, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile);
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile));
		}
	}
}
//Custom styles
.dropdown-toggle::after{
  display: none;
}
.dropdown-item:hover, .dropdown-item:focus{
  background: none!important;
}
.ng-select.ng-select-opened>.ng-select-container, .ng-select .ng-select-container{
  background: #F5F8FA;
  border-color: #F5F8FA;
}
.ng-dropdown-panel.ng-select-bottom{
  border: none;
  box-shadow: 4px 15px 10px -10px rgb(82 63 105 / 15%);
}
.ng-select.ng-select-single .ng-select-container{
  height:43px;
  border: none;
  min-width: 58px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #fff;
  background-color: #009EF7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
  background-size: 0.8rem;
  background-position: center right 1rem;
  background-repeat: no-repeat;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  border-color:transparent!important;
  box-shadow: none!important;
}
//.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
//  color: #5E6278;
//  font-size: 1.1rem;
//  font-weight: 600;
//}
.ng-option .ng-option-label, .ng-select.ng-select-single .ng-select-container
.ng-value-container .ng-value{
  color: #5E6278;
  font-size: 1.1rem;
  font-weight: 600;
}
.ng-dropdown-panel .ng-option-label{
  transition: all .3s ease;
}
.ng-option-selected .ng-option-label{
  color:#ffffff!important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background-color: #F1FAFF;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked .ng-option-label{
  color: #009EF7;
}
.ng-select .ng-select-container{
  width: 99%;
  margin-left: 2px;
}
.cdk-overlay-container{
  z-index: 99999;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  top:10px;
}
.menu-sub-dropdown{
  z-index: 90;
}
.badge-manager-gray{
  color: #000000;
  background-color: #f1faff;
}
.pics-fit{
  object-fit: cover!important;
  background-position: center center!important;
}
.badge.badge-united-states{
  color: #ffffff;
  background: #002561;
}
.badge.badge-ua{
  color: #0058b5;
  background: #f7ce00;
}
.badge.badge-china{
  color: #f7d700;
  background: #d7270f;
}
.badge.badge-oper{
  color: #ffffff;
  background-color: #b43ad7;
}
.badge.badge-agent{
  color: #ffffff;
  background-color: #0ec754;
}
.loading-box{
  width: 100%;
  height: 100%;
  text-align: center;
}

